<template>
  <div>
    <el-upload
      action="https://fleetan-hk-bucket.oss-accelerate.aliyuncs.com"
      :data="dataObj"
      list-type="text"
      :multiple="true"
      :file-list="fileList"
      :before-upload="beforeUpload"
      :on-remove="handleRemove"
      :on-success="handleUploadSuccess"
      :on-preview="handlePreview"
    >
      <el-button size="small" type="primary">{{tipText}}</el-button>
			<div slot="tip" class="el-upload__tip">可上传多个文件</div>
    </el-upload>
  </div>
</template>
<script>
import { policy } from "./policy";
import { createUniqueString } from "@/utils/index";

export default {
  name: "mutipleUploadNoLimit",
  props: {
    value: String,
		tipText:{
			type: String,
			default:function(){
				return '上传文件'
			}
		},
		isClearList:{
			type:Boolean,
			default:false
		},
  },
	watch:{
		//监听按钮名称和提示文字
		'tipText':{
			handler(newValue){
			},
			immediate: true,
		},
		// 是否清空预览文件列表
		isClearList:function(newValue,oldValue){
			console.log(newValue);
			if(newValue == true){
				this.fileList = [];
			}
		}
	},
  computed: {
  },
  data() {
    return {
      dataObj: {
        policy: "",
        signature: "",
        key: "",
        ossaccessKeyId: "",
        dir: "",
        host: ""
      },
			fileList:[],
    };
  },
  methods: {
    emitInput(fileList) {
      this.$emit("input", fileList);
    },
    handleRemove(file, fileList) {
			this.fileList = fileList;
			this.emitInput(this.fileList);
    },
    handlePreview(file) {
			window.open(file.url, "_blank");
    },
    beforeUpload(file) {
			let _self = this;
			let fileNameStr = file.name;
			if(fileNameStr.length > 30){
				return this.$message.warning(this.$t('tipsInfo.uploadFileNameLimitTips'));
			}
      this.$message({
        message: "正在上传，请稍后",
        typpe: "warning"
      });
      return new Promise((resolve, reject) => {
        policy()
          .then(response => {
            _self.dataObj.policy = response.policy;
            _self.dataObj.signature = response.signature;
            _self.dataObj.ossaccessKeyId = response.accessid;
            _self.dataObj.key =
              response.dir + createUniqueString() + "~${filename}";
            _self.dataObj.dir = response.dir;
            _self.dataObj.host = response.host;
            // console.log(_self.dataObj);
            resolve(true);
          })
          .catch(err => {
            reject(false);
          });
      });
    },
    // 上传完成
    handleUploadSuccess(res, file,fileList) {
      this.$message.success("上传成功...");
			let fileName = encodeURIComponent(file.name);
			let url = this.dataObj.host + "/" + this.dataObj.key.replace("${filename}", fileName)
			this.fileList.push({
				name:fileName,
				url:url
			})
      this.emitInput(this.fileList);
    }
  }
};
</script>
<style>
</style>


