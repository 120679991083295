<!-- FBA列表 -->
<template>
	<div 
	:lang="$i18n.locale"
	v-loading.fullscreen="uploadloading"
	:element-loading-text="$t('tipsInfo.uploading')"
	element-loading-spinner="el-icon-loading"
	element-loading-background="rgba(0, 0, 0, 0.8)" 
	class>
		<div class="listHeader">
			<i></i><span>{{$t('commonInfo.orderList')}}</span>
		</div>
		<el-form :model="queryParams" ref="queryForm" :inline="true" label-width="auto" style="margin:10px 0 0 20px">
			<el-form-item label>
				<el-input v-model="companyProductCode" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.clientOrderNoColumn')" type="textarea" clearable />
			</el-form-item>
			<el-form-item label>
				<el-input v-model="FbaNo" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.fbaNo')" type="textarea" clearable />
			</el-form-item>
			<el-form-item label>
				<el-date-picker v-model="value1" type="datetimerange" range-separator="-"
					:start-placeholder="$t('commonInfo.beginTime')" :end-placeholder="$t('commonInfo.endTime')"
					value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" @change="changeDate"
					style="width:350px"></el-date-picker>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" icon="el-icon-search" @click="handleQuery">{{$t('commonInfo.searchBtn')}}</el-button>
				<el-button icon="el-icon-refresh" @click="handleRefresh">{{$t('commonInfo.refreshBtn')}}</el-button>
				<el-button type="primary" icon="el-icon-plus" @click="handleAdd()">{{$t('commonInfo.createBtn')}}</el-button>
			</el-form-item>
		</el-form>

		<!-- 展示 -->
		<el-table style="margin:0 15px;width: calc(100% - 30px)" v-loading="loading" :data="dataList" border>
			<!-- <el-table-column label="系统订单号" align="center" prop="orderNo" /> -->
			<el-table-column :label="$t('commonInfo.clientOrderNoColumn')" align="center" prop="customerOrderNo" width="140" />
			<el-table-column :label="$t('commonInfo.fbaNo') + '/' + $t('commonInfo.asnNo')" align="center" prop="fbaNo" width="130" />
			<el-table-column :label="$t('commonInfo.deliveryDate')" align="center" prop="deliveryDate" width="140">
			  <template slot-scope="{ row }">
					<span v-if="row.deliveryDate == null && row.orderType == 'FBA'">{{$t('commonInfo.fleetanAppointment')}}</span>
					<span v-else>{{row.deliveryDate}}</span>
				</template>
			</el-table-column>
			<el-table-column :label="$t('commonInfo.productCount')" align="center">
				<template slot-scope="{ row }">
					<span>{{row.productList == null?0:row.productList.length}}</span>
				</template>
			</el-table-column>
			<el-table-column :label="$t('commonInfo.pcsCount')" align="center">
				<template slot-scope="{ row }">
					<span>{{countPCS(row)}}</span>
				</template>
			</el-table-column>
			<el-table-column :label="$t('commonInfo.actualCarton')" align="center" prop="actualPackingCount"></el-table-column>
			<!-- <el-table-column label="期望送仓数量" align="center" prop="clientAppointCount" /> -->
			<el-table-column :label="$t('commonInfo.deliveryWarehouse')" align="center" prop="receiverAddress" width="140">
				<template slot-scope="{row}">
					<span>{{row.receiverAddress}}</span>
					<br />
					<span style="color: #0058b3" v-if="row.deliveryType == '2'">{{'(' + $t('commonInfo.selfPickup') +')'}}</span>
				</template>
			</el-table-column>
			<el-table-column :label="$t('commonInfo.orderType')" align="center" prop="orderType" width="115px">
				<template slot-scope="{row}">
					<span>{{row.orderType}}</span><br>
					<span style="color: burlywood;" v-if="row.orderType == 'DS'&&  row.cancelProductCount != 0 ">{{$t('commonInfo.cancelProductCount')}}:{{row.cancelProductCount}}</span>
					<span style="color: burlywood;" v-if="row.orderType == 'DS'&& row.cancelProductCount == 0 && returnNum(row)>0 ">{{$t('commonInfo.cancelProductCount')}}:{{ returnNum(row) }}</span>
				</template>
			</el-table-column>
			<el-table-column :label="'NOON ' + $t('commonInfo.companyId')" align="center" prop="sendCompany" width="150" />
			<!-- 可查看POD -->
			<el-table-column :label="$t('commonInfo.statusColumn')" align="center" prop="orderStatus" width="100" >
				<template slot-scope="scope">
					<div>
						<!-- <span style="display: block;">{{ scope.row.orderStatus }}</span> -->
						<span style="display: block;">{{returnStatusName(scope.row)}}</span>
						<div v-if="isShowPod(scope.row)">
							<el-button type="text" icon="el-icon-picture" style="height: 22px; padding: 0px;"
								size="small" @click="checkPOD(scope.row.podLink)">{{$t('commonInfo.actionCheckPOD')}}</el-button>
						</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column :label="$t('commonInfo.remark')" align="center" prop="exp" width="140">
				<template slot-scope="{row}">
					<span v-if="row.exp == '' || row.exp == null || row.exp.length<60">{{row.exp}}</span>
				  <div v-else>
				    <p>{{row.exp.substring(0,60) + '...'}}</p>
				    <el-button size="mini" type="text"
				    	@click="handleCheckExpDetail(row)">{{$t('commonInfo.actionDetail')}}</el-button>
				  </div>
				</template>
			</el-table-column>
			<el-table-column :label="$t('commonInfo.createTimeColumn')" align="center" prop="initTime" width="160" />
			<el-table-column :label="$t('commonInfo.operationColumn')" align="center" class-name="small-padding fixed-width" fixed="right"
				width="160px">
				<template slot-scope="{ row }">
					<!-- 客户修改送仓时间 只能修改FBN和SB2B订单类型且订单状态限制 -->
					<el-button v-if="returnIsEditTime(row)" size="mini" type="text" icon="el-icon-edit" @click="handleEditTime(row)" style="margin-left:10px">{{$t('commonInfo.actionModifyDeliveryTime')}}</el-button>
					<el-button size="mini" type="text" icon="el-icon-view" @click="handleView(row)" style="margin-left:10px">{{$t('commonInfo.actionCheckProduct')}}</el-button>
					<!-- <el-button v-if="row.orderType == 'FBA'" size="mini" type="text" icon="el-icon-upload2" @click="handleUploadCartonPdf(row)">{{$t('commonInfo.uploadFBAPDF')}}</el-button> -->
					<el-button size="mini" v-if="row.newLabelLinkList != null && row.newLabelLinkList.length > 0" type="text" icon="el-icon-download" @click="handleDownloadNewLabel(row)">{{$t('commonInfo.newLabelFile')}}</el-button>
					<el-button size="mini" type="text" icon="el-icon-s-promotion" @click="handleTrack(row.customerOrderNo)">{{$t('commonInfo.actiontrack')}}</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-image-viewer v-if="showImg" :on-close="closeImg" :url-list="imgList" />
		<!-- 增加修改订单信息 -->
		<el-dialog :lang="$i18n.locale" :title="dialogTitle" :visible.sync="popup" :close-on-click-modal="false" width="980px"
			:append-to-body="true" @close="closeDialog">
			<el-form label-width="150px" :model="dialogForm" ref="OrderForm" :rules="rules" :inline="true">
				<el-form-item :label="$t('commonInfo.orderType')" prop="order_category">
					<el-select v-model="dialogForm.order_category" filterable :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.orderType')" :disabled="isEdit" style="width: 650px;">
						<el-option v-for="item in order_categoryList" :key="item.dictCode" :label="item.dictLabel"
							:value="item.dictValue"></el-option>
					</el-select>
				</el-form-item>
				<div v-if="dialogForm.order_category == 'OWH'">
				  <!-- 自提不需要显示仓库和地址，默认飞坦海外仓，需要填写联系人和联系方式 2023-3-29 -->
				  <el-form-item :label="$t('commonInfo.deliveryMode')" prop="deliveryType">
				  	<el-radio v-model="dialogForm.deliveryType" label="1">{{$t('commonInfo.homeDeliveryService')}}</el-radio>
				  	<el-radio v-model="dialogForm.deliveryType" label="2">{{$t('commonInfo.whSelfPickup')}}</el-radio>
				  </el-form-item>
				</div>
				<div v-if="dialogForm.order_category != ''">
					<el-form-item v-if="dialogForm.order_category != 'DS'" :label="$t('commonInfo.warhouseCode')" prop="receiver_address">
						<el-tag v-if="dialogForm.deliveryType == '2'" type="success">{{$t('commonInfo.fleetanWarehouse')}}</el-tag>
						<el-select v-else v-model="dialogForm.receiver_address" filterable :placeholder="$t('tipsInfo.selectPlaceholder') + $t('commonInfo.deliveryWarehouse')" style="width: 650px;">
							<el-option v-for="item in selectWhList" :key="item.dictCode" :label="item.dictLabel"
								:value="item.dictValue"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="dialogForm.deliveryType == '2'" :label="dialogForm.deliveryType == '1'?$t('commonInfo.contactMan'):$t('commonInfo.consigneeTypeOne')" prop="contacts">
						<el-input v-model="dialogForm.contacts" clearable style="width: 650px;"></el-input>
					</el-form-item>
					<el-form-item v-if="dialogForm.deliveryType == '2'" :label="dialogForm.deliveryType == '1'?$t('commonInfo.contact'):$t('commonInfo.consigneeContact')" prop="contactsTel">
						<el-input v-model="dialogForm.contactsTel" clearable style="width: 650px;"></el-input>
					</el-form-item>
					<el-form-item v-if="dialogForm.order_category == 'FBA'" :label="$t('commonInfo.fbaNo')" prop="fba_no">
						<el-input v-model="dialogForm.fba_no" style="width: 650px;" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.fbaNo')"></el-input>
					</el-form-item>
					<el-form-item v-if="dialogForm.order_category == 'FBA'" :label="$t('commonInfo.fbaNoCode')" prop="fbaNoCode">
						<el-input v-model="dialogForm.fbaNoCode" style="width: 650px;" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.fbaNoCode')"></el-input>
					</el-form-item>
					<el-form-item v-if="dialogForm.order_category == 'FBN' || dialogForm.order_category == 'SB2B'" :label="$t('commonInfo.asnNo')" prop="fba_no">
						<el-input v-model="dialogForm.fba_no" style="width: 650px;" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.asnNo')"></el-input>
					</el-form-item>
					<el-form-item v-if="dialogForm.order_category != 'FBA'" :label="dialogForm.deliveryType == '1'?$t('commonInfo.deliveryDate'):$t('commonInfo.pickupDate') + '(SA)'" prop="delivery_date">
						<el-date-picker
						  clearable
						  style="width: 650px;"
							popper-class="no-atTheMoment"
						  v-model="dialogForm.delivery_date"
							:picker-options="deliveryPickerOptions"
						  type="datetime"
						  value-format="yyyy-MM-dd HH:mm:ss"
						  :placeholder="dialogForm.deliveryType == '1'?$t('tipsInfo.selectPlaceholder') + $t('commonInfo.deliveryDate'):$t('tipsInfo.selectPlaceholder') + $t('commonInfo.pickupDate')"
						></el-date-picker>
						<!-- <el-tooltip placement="top">
						  <div slot="content">预约送仓时间选择规则：<br/>1、选择送仓时间为沙特时间<br/>2、每周五沙特公休<br/>3、国内推单当天0点-15点为正常推单，<br/>  15点到20点为加急推单，需要收取加急费用，<br/>  20点过后不能推当天单<br/>4、提前一天以上为正常推单</div>
						  <i  style="font-size: 28px; position: absolute;top: 6px; right: -40px;"></i>
						</el-tooltip> -->
					</el-form-item>
					<!-- 预约送仓时间提示 -->
					<div class="deliveryTip" v-if="dialogForm.order_category != 'FBA'">
						<h4><i class="el-icon-warning"></i>预约送仓时间选择规则：</h4>
						<span>1、选择送仓时间为沙特时间</span>
						<span>2、每周五沙特公休</span>
						<span>3、国内推单当天时间0点-15点为正常推单，</span>
						<label>15点到20点为加急推单，需要收取加急费用，</label>
						<label>20点过后不能推当天单</label>
						<span>4、提前一天以上为正常推单</span>
					</div>
					<!-- FBN SB2B DS订单类型需要填写店铺ID -->
					<el-form-item v-if="dialogForm.order_category == 'FBN' || dialogForm.order_category == 'SB2B' || dialogForm.order_category == 'DS'" :label="'NOON ' + $t('commonInfo.companyId')" prop="send_company">
						<el-input v-model="dialogForm.send_company" style="width: 650px;"></el-input>
					</el-form-item>
					<!-- OWH类型暂无法知道怎么填写该必传字段，先显示测试 -->
					<el-form-item v-if="dialogForm.order_category == 'OWH'" :label="$t('commonInfo.clientDeliveryNo')" prop="fba_no">
						<el-input v-model="dialogForm.fba_no" style="width: 650px;" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.clientDeliveryNo')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('commonInfo.expectedDeliveryQuantity')" prop="clientAppointCount">
						<el-input v-model="dialogForm.clientAppointCount" oninput="value=value.replace(/^\.+|[^\d.]/g,'')" style="width: 650px;" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.expectedDeliveryQuantity')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('commonInfo.remark')" prop="exp ">
						<el-input type="textarea" v-model="dialogForm.exp " style="width: 650px;" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.remark')"></el-input>
					</el-form-item>
				</div>
			</el-form>
			<el-divider><i class="el-icon-box"></i></el-divider>
			<el-form  :inline="true" label-width="auto" style="margin:10px 0 0 15px">
				<el-form-item label>
					<el-input v-model="searchProductCode" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.clientProductCode')" type="textarea" clearable />
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-plus" @click="handleSearchProduct">{{$t('commonInfo.addBtn')}}</el-button>
				</el-form-item>
				<el-form-item>
					<el-upload
					  class="upload-demo"
					  :action="$baseUrl + '/toborder/wms/product/addMultiFbxStockProduct'"
					  :before-upload="beforeUpload"
					  :on-success="handlesuccess"
					  :on-error="handleError"
					  :on-progress="handleProgress"
					  multiple
					  :show-file-list="false"
					  :file-list="fileList"
					  accept=".xls, .xlsx"
					  :headers="headers"
					  name="productFile"
					  style="margin-right:15px"
					>
					  <el-button type="success">{{$t('ksaWhFBXOrderPage.multipleImportProduct')}}</el-button>
					</el-upload>
				</el-form-item>
				<el-form-item>
					<el-button @click="handleDownloadUrl">{{$t('commonInfo.downloadTemplate')}}</el-button>
				</el-form-item>
				<el-form-item>
					<el-button @click="handleDeleteAll">{{$t('commonInfo.deleteAllBtn')}}</el-button>
				</el-form-item>
			</el-form>
			<el-table style="margin:5px 15px;width: calc(100% - 30px)" ref="productTable"
				v-loading="inventoryLoading" :data="inventoryData" :cell-style="cellStyle" border 
				show-summary :summary-method="getSummaries"
				>
				<!-- <el-table-column label="系统商品编号" align="center" prop="productCode" /> -->
				<el-table-column :label="$t('commonInfo.clientProductCode')" width="110" align="center" prop="clientProductCode" />
				<el-table-column :label="$t('commonInfo.productName')" align="center" prop="productNm" />
				<el-table-column prop="img " :label="$t('commonInfo.photo')" align="center" width="80" >
					<template slot-scope="{ row }">
							<img v-if="row.img   != null && row.img   != ''" :src="row.img  " @click="handleViewImg(row.img)" style="width: 60px;height: 60px;"/>
					</template>
				</el-table-column>
				<el-table-column :label="$t('commonInfo.productQuantity')" align="center" prop="productQuantity" width="80" />
				<el-table-column :label="$t('commonInfo.appointmentQuantity')" align="center" prop="appointmentQuantity" width="130" />
				<el-table-column :label="$t('commonInfo.orderQuantity')" align="center" prop="count">
					<template slot-scope="{ row }">
						<el-input @input="handleNum(row)" v-model.number="row.count" size="small" />
						<span v-if="row.errorNum" style="color: red;font-size: 12px;">{{$t('ksaWhFBXOrderPage.inputCorrectQuantity')}}</span>
					</template>
				</el-table-column>
				<el-table-column :label="$t('ksaWhFBXOrderPage.newLabelCode')" align="center" prop="skuNew" width="130">
					<template slot-scope="{ row }">
						<el-input @input="handleNum(row)" v-model="row.skuNew" size="small" />
						<span v-if="row.errorNewSku" style="color: red;font-size: 12px;">{{$t('tipsInfo.inputPlaceholder') + $t('ksaWhFBXOrderPage.newLabelCode')}}</span>
					</template>
				</el-table-column>
				<el-table-column :label="$t('ksaWhFBXOrderPage.newLabelQuantity')" align="center" prop="swapCnt">
					<template slot-scope="{ row }">
						<el-input @input="handleNum(row)" v-model.number="row.swapCnt" size="small" />
						<span v-if="row.errorNewNum" style="color: red;font-size: 12px;">{{$t('tipsInfo.inputPlaceholder') + $t('ksaWhFBXOrderPage.newLabelQuantity')}}</span>
					</template>
				</el-table-column>
				<el-table-column :label="$t('commonInfo.operationColumn')" align="center" prop="">
					<template slot-scope="{ row }">
						<el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(row.clientProductCode)">{{$t('commonInfo.deleteBtn')}}</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div v-if="!isEdit" style="margin: 10px 0 0 0; display: inline-block;">
				<!-- zpy 2024-4-16 出现客户上传的箱贴文件与海外仓实际装箱数量不符的问题，FBA创建不上传箱贴文件 -->
				<!-- 上传FBX附件 -->
				<SingleUploadPDF
				  v-if="dialogForm.order_category == 'FBA'"
				  v-model="fbxFlienName"
					:tipText="$t('commonInfo.labelFileTips')"
					:preFile="fbxPreName"
				  @input="getFBXInfo"
					class="fileUploadBtn"
				></SingleUploadPDF>
				<!-- 上传FBN/SB2B 送仓文件 和DS类型客户二维码和仓库二维码 -->
				<SingleUploadPDF
				  v-if="dialogForm.order_category == 'FBN' || dialogForm.order_category == 'SB2B' || dialogForm.order_category == 'DS'"
				  v-model="labelFlienName"
					:tipText="labelTipText"
					:preFile="labelPreName"
				  @input="getLabelInfo"
					class="fileUploadBtn"
				></SingleUploadPDF>
				<!-- 当用户下单的SKU需要换标时，填写了换标名和数量则需要上传换标附件 -->
				<MutipleUploadNoLimit 
				  v-if="hasSkuNewItem"
				  :tipText="$t('commonInfo.newLabelFileNolimitTips')"
					:isClearList.sync="clearFileListFlag"
				  @input="getNewLabelExcel"
				  class="fileUploadBtn"
				></MutipleUploadNoLimit>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="cancel" :disabled="submitLoading">{{$t('commonInfo.dialogBtnCancel')}}</el-button>
				<el-button v-if="isEdit" :disabled="submitLoading" type="danger" @click="handleDel()">{{$t('commonInfo.delete')}}</el-button>
				<el-button type="primary" :loading="submitLoading" @click="submit" :disabled="!openBtn || submitLoading">{{submitLoading?$t('commonInfo.dialogBtnSubmiting'):$t('commonInfo.dialogBtnSubmit')}}</el-button>
				
				
			</div>

			<el-image-viewer v-if="showImg" :on-close="closeImg" :url-list="imgList" style="z-index: 9999;"/>
		</el-dialog>
		
		
		
		<el-dialog :lang="$i18n.locale" :itle="$t('commonInfo.productInfo')" :visible.sync="productPopup" :close-on-click-modal="false" width="800px"
			:append-to-body="true">
			<el-table style="margin:5px 10px;width: calc(100% - 10px)" :data="productData" border>
				<el-table-column :label="$t('commonInfo.clientProductCode')" align="center" width="180px" prop="companyProductCode" />
				<el-table-column :label="$t('commonInfo.count')" align="center" prop="productAmount" />
				<el-table-column :label="$t('ksaWhFBXOrderPage.newSku')" align="center" prop="skuNew" />
				<el-table-column :label="$t('ksaWhFBXOrderPage.newSkuCount')" width="140px" align="center" prop="swapCnt" />
				<!-- <el-table-column :label="$t('ksaWhFBXOrderPage.cancelNumber')" align="center" prop="boxLabelNew" />	 -->
			</el-table>
		</el-dialog>
		<el-dialog :lang="$i18n.locale" :title="$t('commonInfo.actionModifyDeliveryTime')" :visible.sync="timePopup" :close-on-click-modal="false" width="600px"
			:append-to-body="true" @close="cancel">
			<el-form label-width="160px" :model="timeForm" ref="timeForm" :inline="true">
				<el-form-item :label="$t('commonInfo.deliveryDate')" prop="delivery_date">
					<el-date-picker
					  clearable
					  size="small"
					  v-model="timeForm.delivery_date"
					  type="datetime"
						style="width: 320px;"
					  value-format="yyyy-MM-dd HH:mm:ss"
					  :placeholder="$t('tipsInfo.selectPlaceholder') + $t('commonInfo.deliveryDate')"
					></el-date-picker>
				</el-form-item>
				<el-form-item>
					<SingleUploadPDF
					  v-model="labelFlienName"
						:tipText="$t('commonInfo.deliveryFileTips')"
						:preFile="labelPreName"
					  @input="getLabelInfo"
					></SingleUploadPDF>
				</el-form-item>
			</el-form>
			<el-footer style="height: 70px; position: relative;">
				<el-button :disabled="timeLoading" :loading="timeLoading" type="primary" @click="submitTime" style="float: right;">{{timeLoading?$t('commonInfo.dialogBtnSubmiting'):$t('commonInfo.dialogBtnSubmit')}}</el-button>
				<el-button :disabled="timeLoading" @click="cancel" style="float: right; margin: 0 10px;">{{$t('commonInfo.dialogBtnCancel')}}</el-button>
			</el-footer>
		</el-dialog>
		<!-- 轨迹对话窗 -->
		<el-dialog :lang="$i18n.locale" :title="$t('commonInfo.trackingTitle')" :visible.sync="popups" width="1100px" append-to-body :close-on-click-modal="false"
			class="timeline" :before-close="handleDialogClose">
			<div style="text-align:right;font-size:18px;color:red">
				<span>{{this.onRemarks}}</span>
				<span v-show="this.isShow">：</span>
				<span>{{this.warehouseTime}}</span>
			</div>
			<div class="dialog_orderNo">
				<label>{{rowOrderNo}}</label>
			</div>
			<el-timeline >
				<el-timeline-item v-for="(activity, index) in activities" :key="index" color="green"
					:timestamp="(activity.initTime +' '+'Time Zone：Beijing')" placement="top">
					<el-card>
						<!-- 轨迹区域 -->
						<h4>{{activity.description}}</h4>
						<!-- location -->
						<p v-show="activity.location==false?false:activity.location==null?false:true">
							{{$t('commonInfo.trackLocation') + ':'}}
							<span style="font-weight:700">{{activity.location}}</span>
						</p>
						<!-- 备注 -->
						<p>
							{{$t('commonInfo.remark') + ':'}}
							<span style="font-weight:700">{{activity.remarks}}</span>
						</p>
					</el-card>
				</el-timeline-item>
			</el-timeline>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="handleDialogClose">{{$t('commonInfo.dialogBtnConfirm')}}</el-button>
			</div>
		</el-dialog>
		<!-- 导入Excel后错误的对话框 -->
		<el-dialog :lang="$i18n.locale" :title="$t('commonInfo.productInfo')" :visible.sync="dialogErrorVisible" width="640px" :close-on-click-modal="false">
		  <el-table :data="errorData">
		    <el-table-column property="clientProductCode" :label="$t('commonInfo.clientProductCode')" align="center"></el-table-column>
				<el-table-column property="count" :label="$t('commonInfo.orderQuantity')" align="center"></el-table-column>
				<el-table-column property="errorMsg" :label="$t('commonInfo.result')" align="center">
				</el-table-column>
		  </el-table>
		</el-dialog>
		<!-- 下载附件 -->
		<el-dialog :lang="$i18n.locale" :title="$t('ksaWhFBXOrderPage.newLabelFileInfo')" :visible.sync="newLabelPopup" width="550px" append-to-body>
		  <el-table :data="newLabelTableData" style="width: 100%" border>
		    <el-table-column type="index" width="55" align="center" />
		    <el-table-column prop="newLabelLink" :label="$t('commonInfo.link')" align="center" :show-overflow-tooltip="true"
		      style="padding-left:10px">
		      <template slot-scope="{row}">
		        <a :href="row.newLabelLink" target="_blank" style="color:#01AAED">{{row.newLabelLink}}</a>
		      </template>
		    </el-table-column>
		  </el-table>
		</el-dialog>
		<!-- 上传箱贴 -->
		<el-dialog :lang="$i18n.locale" :title="$t('commonInfo.uploadFBAPDF')" :visible.sync="uploadFBAPDFPopup" width="450px" append-to-body>
		  <SingleUploadPDF
		    v-model="fbxFlienName"
		  	:tipText="$t('commonInfo.labelFileTips')"
		  	:preFile="fbxPreName"
		    @input="getFBXInfo"
		  ></SingleUploadPDF>
			<el-footer style="height: 50px; position: relative;display: block;margin-top: 20px;">
				<el-button :disabled="submitLoading" :loading="submitLoading" type="primary" @click="handleSubmitUploadFBAPDF" style="float: right;">{{submitLoading?$t('commonInfo.dialogBtnSubmiting'):$t('commonInfo.dialogBtnSubmit')}}</el-button>
				<el-button :disabled="submitLoading" @click="uploadFBAPDFPopup = false" style="float: right; margin: 0 10px;">{{$t('commonInfo.dialogBtnCancel')}}</el-button>
			</el-footer>
		</el-dialog>
		
		<div class="pageBottom">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="queryParams.page" :page-sizes="[10, 20, 30, 40]" :page-size="queryParams.limit"
				layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
	</div>
</template>

<script>
	//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
	//例如：import 《组件名称》 from '《组件路径》';
  import SingleUploadPDF from "@/components/upload/singleUploadPDF";
	import MutipleUploadNoLimit from "@/components/upload/mutipleUploadNoLimit";
	import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
	import SingleUpload from "@/components/upload/singleUpload";
	import {
		formatDate
	} from "@/utils/index";

	export default {
		//import引入的组件需要注入到对象中才能使用
		components: {},
		data() {
			//这里存放数据
			return {
				
				queryParams: {
					page: 1,
					limit: 10,
					customerOrderNos: [],
					fbaNos: [],
					startDate: '', //起始时间 (YYYY-MM-DD HH:mm:ss)
					endDate: '', //结束时间 (YYYY-MM-DD HH:mm:ss)
				},
				companyProductCode: '',
				FbaNo: '',
				loading: false,
				uploadloading:false,
				inventoryLoading: false,
				inventoryData: [], //库存列表
				inventoryParams: {
					page: 1,
					limit: 30,
				},
				inventoryTotal: 0,
				chooseInventory: [],
				openBtn:true,//限制提交按钮
				hasSkuNewItem:false,//判断是否有需要换标SKU，显示换标文件上传按钮
				total: 0,
				value1: [], // 时间
				dataList: [],
				dialogTitle: '',
				clientCreateOrderSkuNum:0,//客户创建订单添加的SKU总数量
				clientCreateOrderSwapNum:0,//客户创建订单添加的SKU需要换标的总数量
				order_categoryList:[
					{
						dictCode:'1',
						dictLabel:'FBA',
						dictValue:'FBA'
					},
					{
						dictCode:'2',
						dictLabel:'FBN',
						dictValue:'FBN'
					},
					{
						dictCode:'3',
						dictLabel:'SB2B',
						dictValue:'SB2B'
					},
					//新增OWH类型，显示沙特海外仓仓库，不显示自定义海外仓，不需要上传送仓文件
					{
					  dictCode:'4',
					  dictLabel:'OWH',
					  dictValue:'OWH'
					},
					// 新增DS类型，默认仓库信息不需要客户填写，fbaNo值不需要传后台自动生成，上传文件为PDF，名称改为“客户二维码和仓库二维码”
					{
					  dictCode:'5',
					  dictLabel:'DS',
					  dictValue:'DS'
					}
				],
				selectWhList:[],
				whType:'',
				popup: false,
				dialogLoading: false,
				submitLoading: false,
				dialogForm: {
					order_category:'',// FBA FBN SB2B
					// customer_order_no: '',
					skuListLink:'',
					label_url:'',//送仓文件
					newLabelLinkList:[],//换标文件
					fba_no: '',
					delivery_date: '',
					receiver_address: '',
					receiver_detail_address: '',
					productList: [],
					clientAppointCount:'',//期望送仓数量，需要和客户添加的商品总数量做对比
					send_company:'',//店铺ID
					exp:'',
					deliveryType:'1',//派送方式 默认送货上门
					contacts:'',//联系人或提货人
					contactsTel:'',//联系人电话或联系人电话
				},
				oldFbaNo:'',//修改时原FBA号，因为接口问题，修改时如果FBA号没有改动则不需要上传，以此做对比
				isEdit: false,
				//上传换标
				newLabelFileLink: [], //换标文件连接 多个
				clearFileListFlag:false,//是否清空换标子组件预览列表
				newLabelPopup:false,
				newLabelTableData:[],
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() > Date.now() + 24 * 60 * 60 * 1000; //可选范围在下一天
					}
				},	
				//已下单商品列表
				orderProductList: [],

				//查看订单商品
				productPopup: false,
				productData: [],
				
				fbxFlienName:'',//上传FBX文件名称
				fbxLink:'',//上传FBX的路径
				fbxPreName:[],//fbx上传的预览文件名集合
				
				labelFlienName:'',//上传送仓文件名称
				labelLink:'',//上传送仓文件的路径
				labelPreName:[],//送仓文件上传的预览文件名集合
				labelTipText:'',//按钮描述，FBN/SB2B 和DS订单类型按钮描述不一样
				
				//搜索商品
				searchProductCode:'',//可批量
				fileList: [],
				
				//添加送仓时间
				timePopup:false,
				timeLoading:false,
				timeForm:{
					productList: [],
					customer_order_no:'',
					delivery_date:'',
					receiver_address: '',
					receiver_detail_address: '',
					label_url:'',
					order_category:'',
					exp:'',
				},
				// 手动给接口添加token值
				headers: {},
				//批量下单商品附件模板
				skuModelUrl:'',
				//查看轨迹
				rowOrderNo:'',
				popups: false,
				activities: [],
				warehouseTime: "",
				onRemarks: "",
				isShow: false,
				
				//查看POD
				imgList: [], //预览POD
				showImg: false,
				filename:[],
				// 导入商品信息错误窗口
				dialogErrorVisible:false,
				errorData:[],
				
				// 上传箱贴
				uploadFBAPDFPopup:false,
				rowData:{},
				
				// 预约送仓时间限制选择范围
				deliveryPickerOptions: {// 限制送仓时间的选择范围
				  disabledDate(time) {
				  	let nowDate = Date.now();
				  	// 国内时间当天下单：0-15点为正常派送时间 15-20点为加急派送时间 20点过后则不能下当天单
				  	let nowHour = new Date().getHours(),nowMin = new Date().getMinutes(),nowSecond = new Date().getSeconds();
				  	if(nowHour < 15){
				  		nowDate = nowDate - 24 * 60 * 60 * 1000;//可选当天
				  	}else if(nowHour >= 15 && nowHour < 20){
				  		nowDate = nowDate - 24 * 60 * 60 * 1000;//可选当天
				  	}else{//不可选当天
				  	}
				  	let nowDay = new Date(time).getDay();//周几
				  	// 超过当前时间的日期不可选
				  	return nowDate > time.getTime() || 
				  	nowDay == 5
				  },
				  selectableRange: '10:00:00 - 18:00:00',
				}
			};
		},
		components: {
		  SingleUploadPDF,
			ElImageViewer,
			SingleUpload,
			MutipleUploadNoLimit
		},
		//监听属性 类似于data概念
		computed: {
			rules() {// 表单验证需要在计算属性定义，才能实现实时更新国际化提示信息
			  //自定义验证规则 只能填写数字或者英文字母
			  const validateCode = (rule, value, callback) => {
			    let reg = /[^\w\.\/]/ig
			    if(reg.test(value)){
			  		callback(new Error(this.$t('commonInfo.numberAndLetterLimit')))
			    }else{
			      callback()
			    }
			  };
				return {
					order_category: [{
						required: true,
						message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.orderType'),
						trigger: 'blur'
					}],
					fba_no: [{
						required: true,
						message: this.$t('tipsInfo.inputPlaceholder') + this.$t('tipsInfo.info'),
						trigger: 'blur'
					},{
						validator:validateCode, 
						trigger:'blur',
					}],
					fbaNoCode: [{
						required: true,
						message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.fbaNoCode'),
						trigger: 'blur'
					},{
					  min: 8,
						max: 8,
						message: this.$t('commonInfo.fbaNoCodeLimit'),
						trigger: 'change'
					},{
					  validator:validateCode, 
					  trigger:'blur',
					}],
					delivery_date:[{
						required: true,
						message: this.dialogForm.deliveryType == '1'?this.$t('tipsInfo.selectPlaceholder') + this.$t('commonInfo.deliveryDate'):this.$t('tipsInfo.selectPlaceholder') + this.$t('commonInfo.pickupDate'),
						trigger: 'blur'
					}],
					asn_no: [{
						required: true,
						message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.asnNo'),
						trigger: 'blur'
					},{
					  validator:validateCode, 
					  trigger:'blur',
					}],
					receiver_address: [{
						required: true,
						message: this.$t('tipsInfo.selectPlaceholder') + this.$t('commonInfo.warhouseCode'),
						trigger: 'blur'
					}],
					clientAppointCount:[{
						required: true,
						message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.expectedDeliveryQuantity'),
						trigger: 'blur'
					}],
					send_company:[{
						required: true,
						message: this.$t('tipsInfo.inputPlaceholder') + 'NOON ' + this.$t('commonInfo.companyId'),
						trigger: 'blur'
					},{
					  min: 5,
						max: 6,
						message: this.$t('commonInfo.companyLimit'),
						trigger: 'blur'
					}],
					deliveryType:[{
					  required: true,
					  message: this.$t('tipsInfo.selectPlaceholder') + this.$t('commonInfo.deliveryMode'),
					  trigger: 'blur'
					}],
					contacts:[{
					  required: true,
					  message: this.$t('tipsInfo.inputPlaceholder') + this.dialogForm.deliveryType == '1'?this.$t('commonInfo.contactMan'):this.$t('commonInfo.consigneeTypeOne'),
					  trigger: 'blur'
					}],
					contactsTel:[{
					  required: true,
					  message: this.$t('tipsInfo.inputPlaceholder') + this.dialogForm.deliveryType == '1'?this.$t('commonInfo.contact'):this.$t('commonInfo.consigneeContact'),
					  trigger: 'blur'
					}]
				}
				
			},
		},
		//监控data中的数据变化
		watch: {
			'dialogForm.order_category': {
				immediate: true,
				handler(val) {
					this.dialogForm.receiver_address = '';
					this.selectWhList = [];
					this.dialogForm.deliveryType = '1';
					this.dialogForm.contacts = '';
					this.dialogForm.contactsTel = '';
					if (val != '' && val != null) {
						this.getWhDict(val); //获取仓库信息
					}
				}
			},
			'dialogForm.deliveryType': {
			  immediate: true,
			  handler(val) {
			    this.dialogForm.contacts = '';
			    this.dialogForm.contactsTel = '';
			  	// 选择自提默认Fleetan海外仓地址
			    if(val == '2'){
			      this.dialogForm.receiver_address = 'Fleetan';
			    }else{
			      this.dialogForm.receiver_address = '';
			    }
			  }
			},
			'dialogForm.receiver_address':{
				immediate: true,
				handler(val) {
					if (val != '' && val != null) {
						this.getWhInfo(val); //获取仓库信息
					}
				}
			},
			value1() {
				if (this.value1.length === 2) {
					// console.log(this.value1);
					this.queryParams.startDate = this.value1[0];
					this.queryParams.endDate = this.value1[1];
				}
			},
			// 监听客户选择的派送时间，用于限制小时的选择范围
			'dialogForm.delivery_date':function(newVal,oldVal){
				console.log(newVal);
				// 判断是否选择当天，不是当天可以选择小时10-18点
				let nowDate = new Date();
				let chooseDate = new Date(newVal);
				let updateTime = '';
				// 当天下单
				if(nowDate.getMonth() + nowDate.getDate() == chooseDate.getMonth() + chooseDate.getDate()){
					if(nowDate.getHours() < 15){
						updateTime = '10:00:00 - 18:00:00';
					}else if(nowDate.getHours() >= 15 && nowDate.getHours() < 20){
						updateTime = nowDate.getHours() - 4 + ':00:00 - 18:00:00';
					}
				}else{// 不是当天不用限制小时
					updateTime = '10:00:00 - 18:00:00';
				}
				this.$set(this.deliveryPickerOptions,'selectableRange',updateTime);
			}
		},
		//方法集合
		methods: {
			// 监听日期选择器
			changeDate(val) {
				// console.log(val);
				if (!val) {
					this.value1 = [];
					this.queryParams.startDate = "";
					this.queryParams.endDate = "";
				}
			},
			// 分页条数
			handleSizeChange(size) {
				this.queryParams.limit = size;
				this.queryParams.page = 1;
				this.getList();
			},
			// 分页页数
			handleCurrentChange(page) {
				this.queryParams.page = page;
				this.getList();
			},

			// 分页条数
			handleInventoryChange(size) {
				this.inventoryParams.limit = size;
				this.inventoryParams.page = 1;
				this.getInventoryList();
			},
			// 分页页数
			handleInventoryCurrentChange(page) {
				this.inventoryParams.page = page;
				this.getInventoryList();
			},
			//改变表格颜色
			cellStyle({row,column,rowIndex,columnIndex}){
				if(row['isError'] == true){
					return {
						background:'burlywood'
					}
				}
			},
			// 判断输入数据
			handleNum(row){
				this.checkProduct();
			},
			//关闭图片
			closeImg() {
				this.showImg = false;
			},
			isShowPod(row) { //判断数据状态 只有已签收n800或部分签收n710状态才可以查看
				let isShow = false;
				if (row.statusCode == 'n710' || row.statusCode == 'n800') {
					if (row.podLink != null && row.podLink != '') {
						isShow = true;
					}
				}
				return isShow
			},
			//查看POD图片
			checkPOD(url) {
				this.imgList = [];
				//显示预览图片　
				if (url.length > 0) {
					var that = this;
					that.showImg = true;
					url.forEach(item => {
						that.imgList.push(item);
					})
				} else {
					this.$message.error(this.$t('tipsInfo.noPodInfo'));
				}
			
			},
			// 获取SKU换标附件
			getNewLabelExcel(data) {
				this.newLabelFileLink = [];
				if(data.length > 0){
					data.forEach(item => {
						this.newLabelFileLink.push(item.url);
					})
				}
			},
			// 检测商品列表是否正确 2022-3-26
			checkProduct(){
				if(this.inventoryData.length == 0){
					return
				}
				let that = this;
				let isAllRight = true;
				this.hasSkuNewItem = false;//判断是否有填写换标信息，有则显示上传换标文件按钮
				const ruleInt = /^\+?[1-9][0-9]*$/;//正整数
				this.inventoryData.forEach(item => {
					item.isError = false;
					// 三种错误情况
					item.errorNum = false;
					item.errorNewSku = false;
					item.errorNewNum = false;
					if(!ruleInt.test(item.count)){
						item.isError = true;
						item.errorNum = true;
					}
					if(item.count > (item.productQuantity - item.appointmentQuantity)){
						item.isError = true;
						item.errorNum = true;
					}
					// 换标时换标SKU和换标数量要都必填或者都为空
					if(item.skuNew != ""){
						if(item.swapCnt == 0 || item.swapCnt == ''){
							item.isError = true;
							item.errorNewNum = true;
						}else{
							this.hasSkuNewItem = true;
						}
					}
					if(item.swapCnt != "" && item.swapCnt != 0){
						if(item.skuNew == ""){
							item.isError = true;
							item.errorNewSku = true;
						}else{
							this.hasSkuNewItem = true;
						}
						if(item.swapCnt > item.count){
							item.isError = true;
							item.errorNewNum = true;
						}
					}
					//判断是否全部数据下单数量没问题
					if(item.isError){
						isAllRight = false;
					}
				})
				// 数据有问题禁用提交按钮
				this.openBtn = isAllRight?true:false;
			},
			//获取上传FBA返回的路径
			getFBXInfo(data) {
				this.fbxLink = data;
			  if(data != ''){
					//修改upload的preview预览
					let str = data.split("~");
					this.fbxPreName = [{
						name:str[1],
						url:data,
					}]
				}
			},
			// 获取送仓文件返回路径
			getLabelInfo(data) {
				this.labelLink = data;
			  if(data != ''){
					//修改upload的preview预览
					let str = data.split("~");
					this.labelPreName = [{
						name:str[1],
						url:data,
					}]
				}
			},
			// 根据状态码显示信息 中英文
			returnStatusName(row){
				let name = '';
				switch(row.statusCode){
					case '101': name = this.$t('B2BStatus.creating'); break;
					case 'n100': name = this.$t('B2BStatus.init'); break;
					case 'n101': name = this.$t('B2BStatus.warehousing'); break;
					case 'n200': name = this.$t('B2BStatus.cnReceive'); break;
					case 'n240': name = this.$t('B2BStatus.cnPartialShipment'); break;
					case 'n300': name = this.$t('B2BStatus.cnOutscan'); break;
					case '-300': name = this.$t('B2BStatus.cnOutscanExceptionPart'); break;
					case '-302': name = this.$t('B2BStatus.cnTransitExceptionCheck'); break;
					case 'n301': name = this.$t('B2BStatus.cnAir'); break;
					case '-305': name = this.$t('B2BStatus.cnTransitException'); break;
					case '307': name = this.$t('B2BStatus.cnTransitExceptionOr'); break;
					case '-310': name = this.$t('B2BStatus.cnAirExceptionDelay'); break;
					case '-311': name = this.$t('B2BStatus.cnAirExceptionCancel'); break;
					case 'n302': name = this.$t('B2BStatus.airArrived'); break;
					case 'n400': name = this.$t('B2BStatus.customsClearanceCompLated'); break;
					case '-401': name = this.$t('B2BStatus.customsClearanceComplatedExceptionCheck'); break;
					case '-402': name = this.$t('B2BStatus.customsClearanceComplatedExceptionDeduction'); break;
					case 'n510': name = this.$t('B2BStatus.overseasArrived'); break;
					case 'n500': name = this.$t('B2BStatus.ksaInscan'); break;
					case '-520': name = this.$t('B2BStatus.cartonToSku'); break;
					case 'n699': name = this.$t('B2BStatus.ksaReadyForDispatch'); break;
					case 'n700': name = this.$t('B2BStatus.delivering'); break;
					case '-700': name = this.$t('B2BStatus.deliveryFailed'); break;
					case 'n710': name = this.$t('B2BStatus.partialreceipt'); break;
					case 'n800': name = this.$t('B2BStatus.successfulDelivery'); break;
					case '-800': name = this.$t('B2BStatus.signingFailed'); break;
					case '-100': name = this.$t('B2BStatus.cancelOrder'); break;
					case '-600': name = this.$t('B2BStatus.transferThirdpartyDelivery'); break;
					// 未识别或者新加状态，暂时用后台返回的状态名称
					default: name = row.orderStatus; break;
				}
				return name
			},
			countPCS(row) {
				if (row.productList.length == 0) {
					return 0;
				} else {
					let pcsNum = 0;
					row.productList.forEach(item => {
						pcsNum += item.productAmount;
					})
					return pcsNum;
				}
			},
			async getList() {
				this.loading = true;
				this.dataList = [];
				if (this.FbaNo != '') {
					this.queryParams.fbaNos = this.strToArr(this.FbaNo);
				}else{
					this.queryParams.fbaNos = [];
				}
				if (this.companyProductCode != '') {
					this.queryParams.customerOrderNos = this.strToArr(this.companyProductCode);
				}else{
					this.queryParams.customerOrderNos = [];
				}
				const res = await this.$http.post("/toborder/wms/fulfillment/query", this.queryParams);
				if (res.code === 200) {
					let data = res.data.list;
					// 总共有多少条数据，分页
					this.total = res.data.totalCount;
					res.data.list.forEach(item => {
					  item.productList = item.productList || [];
					})
					this.dataList = JSON.parse(JSON.stringify(res.data.list));
					
					this.loading = false;
				} else {
					this.loading = false;
					this.$message.error(res.msg);
				}
			},
			//获取仓库数据
			async getWhDict(type) {
				let dictText = '';
				switch (type) {
					case 'FBA':
						dictText = 'sys_SA_FBAWh';
						break;
					case 'FBN':
						dictText = 'sys_SA_NOONWh';
						this.labelTipText = this.$t('commonInfo.deliveryFileTips');
						break;
					case 'SB2B':
						dictText = 'sys_SA_NOONWh';
						this.labelTipText = this.$t('commonInfo.deliveryFileTips');
						break;
					case 'OWH':
					  dictText = 'sys_SA_OtherWh';
						break;
					// DS 类型默认仓库，不需要给用户选择
					case 'DS':
					  dictText = 'sys_SA_OtherWh';
					  this.labelTipText = this.$t('commonInfo.barcodeFileTips');
					  break;
				}
				await this.$http.get("/toborder/system/dict/data/list?dictType=" + dictText).then(res => {
					if (res.code == 200) {
						this.selectWhList = res.rows;
						if(type == 'OWH'){// 如果是OWH类型，仓库列表不显示自定义海外仓
						  this.selectWhList = this.selectWhList.filter(item => {
						    return item.dictValue != 'custom';
						  })
						}
						if(type == 'DS'){// 如果为DS类型，默认上传固定仓库信息，不需要显示
						  // let dsWh = this.selectWhList.filter(item => {
						  //   return item.dictValue == '亦邦海外仓';
						  // })
						  //默认仓库信息 暂时写死
						  this.dialogForm.receiver_address = 'Noon - DS';
						  this.dialogForm.receiver_detail_address = 'Noon - DS';
						}
					} else {
						this.$message.error(res.msg);
					}
				}).catch(err => {
					this.$message.error(err.msg);
					this.loading = false;
				})
			},
			//选择仓库时自定填充仓库地址信息
			getWhInfo(val) {
				let that = this;
				setTimeout(function() {
					that.selectWhList.forEach(item => {
						if (item.dictValue == val) {
							that.dialogForm.receiver_detail_address = item.remark;
						}
					})
				}, 300)
			},
			handleQuery() {
				this.queryParams.page = 1;
				this.getList();
			},
			handleRefresh() {
				this.queryParams = {
					page: 1,
					limit: 10,
				}
				this.getList();
			},
			// 2022-6-15 zpy 客户添加商品需要显示总商品数量和换标总数量合计
			getSummaries(param) {
				let that = this;
				const { columns, data	} = param;
				const sums = [];
				columns.forEach((column, index) => {
					if (index === 0) {
					  sums[index] = this.$t('commonInfo.secondTotal');
			      return;
			    }
					if(column.label == this.$t('commonInfo.orderQuantity')){
						let gwSum = 0;
						data.map(item => {
							let gwValue = item.count;
							if(gwValue == null || gwValue == undefined){
								gwValue = 0;
							}
							gwSum += Number(gwValue);
						});
						sums[index] = gwSum ==0?'':gwSum;
						this.clientCreateOrderSkuNum = gwSum;
					} else if(column.label == this.$t('ksaWhFBXOrderPage.newLabelQuantity')){
						let cwSum = 0;
						data.map(item => {
							let cwValue = item.swapCnt;
							if(cwValue == null || cwValue == undefined){
								cwValue = 0;
							}
							cwSum += Number(cwValue);
						});
						sums[index] = cwSum == 0?'':cwSum;
						this.clientCreateOrderSwapNum = cwSum;
					} else if(column.label == this.$t('commonInfo.appointmentQuantity')){
						sums[index] = this.$t('commonInfo.secondTotal') + this.$t('commonInfo.orderQuantity') + ':';
					} else if(column.label == this.$t('ksaWhFBXOrderPage.newLabelCode')){
						sums[index] = this.$t('commonInfo.secondTotal') + this.$t('ksaWhFBXOrderPage.newLabelQuantity') + ':';
					}else{	
						sums[index] = '';
					}
				});
				return sums;
			},
			// 2022-3-15，搜索列表功能改为根据用户输入的商品编码，直接添加商品到列表
			handleSearchProduct(){
				let query = {
					companyProductCodeList:[],
					page:1,
					limit:10,
				}
				if(this.searchProductCode == ''){
					return this.$message.warning(this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.clientProductCode'));
				}else{
					query.companyProductCodeList = this.strToArr(this.searchProductCode);
					query.limit = query.companyProductCodeList.length;
				}
				this.inventoryParams = query;
				this.getInventoryList();
			},
			// 2022-3-15 删除已添加的商品列表数据
			handleDelete(code){
				let that = this;
				this.$confirm(this.$t('tipsInfo.deleteRowTips'), this.$t('tipsInfo.warning'), {
						confirmButtonText: this.$t('commonInfo.dialogBtnConfirm'),
						cancelButtonText: this.$t('commonInfo.dialogBtnCancel'),
						type: "warning"
					})
					.then(() => {
						var index = this.inventoryData.findIndex(item => {
							if(item.clientProductCode == code){
								return true;
							}
						})
						this.inventoryData.splice(index, 1);
						this.checkProduct();
						this.$message.success(this.$t('tipsInfo.deleteSuccessTips'));
					})
					.catch(() => {});
			},
			// 多选选中的数据
			handleSelectionChange(selection) {
				// selection.forEach(item => {
				// 	item.toEdit = true;
				// });
				this.chooseInventory = selection;
			},
			handleAdd() {
				// this.chooseInventory = [];
				this.dialogTitle = this.$t('ksaWhFBXOrderPage.createOrderInfo');
				this.isEdit = false;
				// this.getInventoryList();
				this.popup = true;
				this.getModelUrl();
			},
			async getModelUrl(){
				const res = await this.$http.get("/toborder/system/dict/data/list",{
				  params: {
						page:1,
						limit:10,
						dictType:'sys_template_url'
					}
				});
				
				if (res.code === 200) {
					let that = this;
					let data = res.rows;
					data.forEach(item => {
						if(item.dictLabel == 'fulfillment FBX订单下单模版'){
							that.skuModelUrl = item.remark;
						}
					})
				} else {
				  this.$message.error(res.msg);
				}
			},
			// 下载清单模板
			handleDownloadUrl(){
				window.open(this.skuModelUrl);
			},
			// 删除全部商品列表数据
			handleDeleteAll(){
				this.$confirm(this.$t('tipsInfo.deleteAllRowTips'), this.$t('tipsInfo.warning'), {
						confirmButtonText: this.$t('commonInfo.dialogBtnConfirm'),
						cancelButtonText: this.$t('commonInfo.dialogBtnCancel'),
						type: "warning"
					}
					).then(() => {
						this.inventoryData = [];
						// 执行删除全部操作将上传文件地址清空
						this.dialogForm.skuListLink = '';
					})
					.catch(() => {});
			},
			// 修改商品
			handleEdit(row) {
				this.chooseInventory = [];
				//获取库存列表并且勾选已选中数据
				this.getInventoryList();
				this.dialogTitle = this.$t('ksaWhFBXOrderPage.modifyOrderInfo');
				this.popup = true;
				// this.dialogForm.customer_order_no = row.customerOrderNo;
				this.dialogForm.fba_no = row.fbaNo;
				this.oldFbaNo = row.fbaNo;
				this.dialogForm.delivery_date = row.deliveryDate;
				this.dialogForm.receiver_address = row.receiverAddress;
				this.dialogForm.receiver_detail_address = row.receiverDetailAddress;
				this.dialogForm.exp = row.exp;
				// this.fbxLink = row.fbxLink;
				// if(this.fbxLink != ''){
				// 	//修改upload的preview预览
				// 	let str = this.fbxLink.split("~");
				// 	this.fbxPreName = [{
				// 		name:str[str.length - 1],
				// 		url:this.fbxLink,
				// 	}]
				// }
				//获取商品列表个别属性，方便后面判断是否改变
				if(row.productList.length != 0){
					row.productList.forEach(item => {
						this.orderProductList.push({
							sku_old: item.companyProductCode,
							product_amount: item.productAmount,
							marks: item.marks,
						})
					})
				}else{
					this.orderProductList = [];
				}
				// this.orderProductList = row.productList;
				this.isEdit = true;
			},
			// 查看过多信息的订单备注
			handleCheckExpDetail(row){
			  let exp = row.exp.split(']');
			  let message = '';
			  exp.forEach(item => {
			    message += item + ']</br>';
			  })
			  message = message.substr(0,message.length - 6)
			  let html = "<span style='word-break:break-word'>"+message+"</span>";
			
			  this.$alert(html, this.$t('commonInfo.remark'), {
			    confirmButtonText: this.$t('commonInfo.dialogBtnConfirm'),
			    dangerouslyUseHTMLString:true,
			  });
			},
			//修改送仓时间
			handleEditTime(row){
				this.timePopup = true;
				this.timeForm.customer_order_no = row.customerOrderNo;
				this.timeForm.receiver_address = row.receiverAddress;
				this.timeForm.receiver_detail_address = row.receiverDetailAddress;
				this.timeForm.exp = row.exp;
				this.timeForm.order_category = row.orderType;
			},
			//提交送仓时间信息
			submitTime(){
				if(this.timeForm.delivery_date == ''){
					return this.$message.warning(this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.deliveryDate'));
				}
				if(this.labelLink == ''){
					return this.$message.warning(this.$t('tipsInfo.submitOrderDeliveryFileTips'));
				}
				this.timeForm.label_url = this.labelLink;
				//客户确认
				this.$confirm(
						this.$t('ksaWhFBXOrderPage.submitDeliveryTimeTips') + this.timeForm.delivery_date,
						this.$t('tipsInfo.warning'), {
							confirmButtonText: this.$t('commonInfo.dialogBtnConfirm'),
							cancelButtonText: this.$t('commonInfo.dialogBtnCancel'),
							type: "warning"
						}
					).then(() => {
						this.timeLoading = true;
						this.$http
							.post("/toborder/wms/fulfillment/update", this.timeForm)
							.then(res => {
								if (res.code === 200) {
									this.$message.success(this.$t('tipsInfo.success'));
									this.timePopup = false;
									this.timeLoading = false;
									this.getList();
								} else {
									this.timeLoading = false;
									this.$message.error(res.msg);
								}
							}).catch(err => {
								this.timeLoading = false;
								this.$message.error(err.msg);
							});
					})
					.catch(() => {});
				
			},
			// 判断是否显示修改按钮
			returnIsEditTime(row){
				let isTrue = false;
				if(row.orderType == 'FBN' || row.orderType == 'SB2B' || row.orderType == 'DS'){
					if(row.statusCode == 'n100'){
						isTrue = true;
					}
				}
				return isTrue;
			},
			// 查看商品
			handleView(row) {
				this.productPopup = true;
				this.productData = row.productList;
			},
			// 上传箱贴
			handleUploadCartonPdf(row) {
				this.fbxPreName = [];
				this.fbxLink = row.fbaBoxPdfLink;
				if(this.fbxLink != ''){
					//修改upload的preview预览
					let str = this.fbxLink.split("~");
					this.fbxPreName = [{
						name:str[str.length - 1],
						url:this.fbxLink,
					}]
				}
				this.uploadFBAPDFPopup = true;
				this.rowData = row;
			},
			// 提交上传箱贴数据
			handleSubmitUploadFBAPDF() {
				let query = {
					id:this.rowData.id,
					fbaBoxPdfLink:this.fbxLink,
				}
				this.submitLoading = true;
				this.$http.post('/toborder/wms/fulfillment/updateFBXOrder', query).then(res => {
					if (res.code == 200) {
						this.$message.success(this.$t('tipsInfo.success'));
						this.submitLoading = false;
						this.uploadFBAPDFPopup = false;
						this.rowData = {};
						this.getList();
					} else {
						this.submitLoading = false;
						this.$message.error(res.msg);
					}
				}).catch(error => {
					this.submitLoading = false;
					this.$message.error(error.msg);
				})
			},
			// 下载换标文件 2022-5-30 zpy
			handleDownloadNewLabel(row){
				// 显示窗口下载换标文件
				this.newLabelPopup = true;
				this.newLabelTableData = row.newLabelLinkList;
			},
			// 查看轨迹
			async handleTrack(id){
				const res = await this.$http.get(
					"/toborder/trackinfo/" + id
				);
				this.rowOrderNo = id;
				if (res.code === 200) {
					this.activities = res.data.mainOrderTrackingList;
					this.popups = true;
					if (this.activities.length === 0) {
						this.popups = false;
						//输入的订单号不存在
						this.$message.error(this.$t('tipsInfo.orderNoExistTips'));
					}
					this.activities.forEach(item => {
				
						if (item.status == 1000 && item.id) {
							this.warehouseTime = item.description;
							this.onRemarks = item.remarks;
							this.isShow = true;
						}
						
						item.initTime = formatDate(item.initTime);
				
				// 		let indexLocation = item.location.indexOf(":");
				// 		item.location = item.location.substring(
				// 			indexLocation + 1,
				// 			item.location.length
				// 		);
				
				// 		let indexRemarks = item.remarks.indexOf(":");
				// 		item.remarks = item.remarks.substring(
				// 			indexRemarks + 1,
				// 			item.remarks.length
				// 		);
					});
				}
			},
			handleDialogClose() {
				// alert(1);
				this.popups = false;
			},
			// 获取库存列表 2022-3-15 功能改为添加商品，根据搜索商品编号获取
			async getInventoryList() {
				this.inventoryLoading = true;
				const res = await this.$http.post("/toborder/wms/product/getInventoryInformation", this.inventoryParams);

				if (res.code === 200) {
					this.inventoryLoading = false;
					// 2022-3-15 根据用户输入的商品编码，直接添加为订单下单商品
					let dataList = res.data.list;
					// this.inventoryTotal = res.data.totalCount;
					if(dataList != 0){
						dataList.forEach(item => {
							// item.toEdit = true;
							item.isError = true;
							item.skuNew = '';//换标标签	
							item.swapCnt = '';//换标数量
							if(item.productImages != null && item.productImages != ''){
								let img = JSON.parse(item.productImages);
								item.img = img;
							}
						});
					}
					const dataLength = this.inventoryData.length;
					this.inventoryData = this.inventoryData.concat(dataList);
					this.inventoryData = this.quchong(this.inventoryData);
					if(this.inventoryData.length == dataLength){
						this.$message({
              title: this.$t('tipsInfo.info'),
              message: this.$t('ksaWhFBXOrderPage.checkProductTips'),
              duration: 3000,
							type: 'warning'
            });
					}
					this.checkProduct();
				} else {
					this.inventoryLoading = false;
					this.$message.error(res.msg);
				}
			},
			//去重数组
			quchong(arr) {
				const res = new Map();
				return arr.filter(
					(list) => !res.has(list.clientProductCode) && res.set(list.clientProductCode, 1)
				);
			},
			//勾选已保存商品列表
			toCheckList() {
				if (this.isEdit) {
					this.$nextTick(() => {
						for (let i = 0; i < this.inventoryData.length; i++) {
							for (let j = 0; j < this.orderProductList.length; j++) {
								//两个数组做比对,选中的做勾选
								if (this.inventoryData[i].companyProductCode == this.orderProductList[j].sku_old) {
									this.inventoryData[i].toEdit = true;
									this.inventoryData[i].isError = false;
									this.inventoryData[i].count = this.orderProductList[j].product_amount;
									this.chooseInventory.push(this.inventoryData[i]);
									this.$refs.productTable.toggleRowSelection(this.inventoryData[i]);
								}
							}
						}
					});
				}
			},
			//删除
			handleDel() {
				this.$confirm(
						this.$t('ksaWhFBXOrderPage.deleteOrderTips'),
						this.$t('tipsInfo.warning'), {
							confirmButtonText: this.$t('commonInfo.dialogBtnConfirm'),
							cancelButtonText: this.$t('commonInfo.dialogBtnCancel'),
							type: "warning"
						}
					).then(() => {
						this.$http
							.post("/toborder/wms/fulfillment/del", {
								fbaOrderIds: [this.dialogForm.customer_order_no],
							})
							.then(res => {
								if (res.code === 200) {

									this.$message.success(this.$t('tipsInfo.deleteSuccessTips'));
									this.popup = false;
									this.getList();
								} else {
									this.$message.error(res.msg);
								}
							});
					})
					.catch(() => {});
			},
			// 提交
			submit() {
				// zpy 2024-4-16 FBA创建订单暂不需要上传箱贴
				if(this.dialogForm.order_category == 'FBA'){
					if(this.fbxLink == '' || this.fbxLink == null){
						this.$message.warning(this.$t('tipsInfo.submitOrderDeliveryMarkTips'));
						return; 
					}
					
				}
				if(this.dialogForm.order_category == 'FBN' || this.dialogForm.order_category == 'SB2B' || this.dialogForm.order_category == 'DS'){
					if(this.labelLink == '' || this.labelLink == null){
						this.$message.warning(this.$t('ksaWhFBXOrderPage.uploadPreTips') + this.labelTipText.split('-')[0]);
						return; 
					}
				}
				// 上传换标文件
				if(this.hasSkuNewItem){
					if(this.newLabelFileLink.length == 0){
						this.$message.warning(this.$t('ksaWhFBXOrderPage.uploadPreTips') + this.$t('commonInfo.newLabelFile'));
						return; 
					}
				}
				let isOver = false,
					product = [];
				if (this.inventoryData.length == 0) {
					return this.$message.warning(this.$t('ksaWhFBXOrderPage.selectProductTips'));
				}
				this.inventoryData.forEach(item => {
					if (item.count != '') {
						if (parseInt(item.count) > parseInt(item.productQuantity) - parseInt(item.appointmentQuantity)) {
							isOver = true;
						} else {
							product.push({
								sku_old: item.clientProductCode,
								product_amount: item.count,
								sku_new:item.skuNew,
								swap_cnt:item.swapCnt,
								marks: '1', //箱唛必填，暂时固定值为1
							})
						}
					}
				})
				if (isOver) {
					return this.$message.warning(this.$t('ksaWhFBXOrderPage.overOrderCountTips'));
				}
				
				
				this.dialogForm.productList = product;
				this.dialogForm.order_box_mark = this.fbxLink;
				this.dialogForm.label_url = this.labelLink;
				this.dialogForm.newLabelLinkList = this.newLabelFileLink;//换标文件 数组
				
				//是否有修改，update_identity为修改标识
				let url = '';
				if (this.isEdit) {
					this.dialogForm.update_identity = true;
					//如果FBA号没改动，则不需要上传
					if(this.oldFbaNo == this.dialogForm.fba_no){
						delete this.dialogForm.fba_no;
					}
					//修改暂时去除附件地址
					delete this.dialogForm.order_box_mark;
					if(JSON.stringify(this.orderProductList) === JSON.stringify(this.dialogForm.productList)){
						this.dialogForm.productList = [];
					}
					url = '/toborder/wms/fulfillment/update';
				} else {
					url = '/toborder/wms/fulfillment/add';
				}
				
				this.$refs["OrderForm"].validate(valid => {
					if(valid){
						// 判断是否为加急单 当天下单15-20点
						let isUrgent = false;
						let nowDate = new Date();
						let chooseDate = new Date(this.dialogForm.delivery_date);
						if(nowDate.getDate() == chooseDate.getDate() && nowDate.getHours() > 15){
							isUrgent = true;
						}
						// 判断添加的商品总数量和填写的期望送仓数量是否一致，提醒用户
						if(this.clientCreateOrderSkuNum == parseInt(this.dialogForm.clientAppointCount)){
							if(isUrgent){
								this.$confirm(
										"<p style='color:#ff6868;font-weight:bold;'>"+ this.$t('ksaWhFBXOrderPage.urgentOrderTip') +"</p>" +
										 '<p>'+this.$t('ksaWhFBXOrderPage.confirmToCreateOrderTips')+'</p>',
										  this.$t('tipsInfo.tip'), {
											dangerouslyUseHTMLString: true,
											confirmButtonText: this.$t('commonInfo.dialogBtnConfirm'),
											cancelButtonText: this.$t('commonInfo.dialogBtnCancel'),
											type: "warning"
										}
									).then(() => {
										this.submitOrderInfo(url);
									})
									.catch(() => {});
							}else{
								this.submitOrderInfo(url);
							}
						}else{
							let str = '';
							if(isUrgent){
								str = this.$t('ksaWhFBXOrderPage.urgentOrderTip');
							}
							this.$confirm(
							    "<p style='color:#ff6868;font-weight:bold;'>"+ str +"</p>" +
									"<p>"+ this.$t('ksaWhFBXOrderPage.orderCountDiffTips') +"</p>" +
									 '<p>'+this.$t('ksaWhFBXOrderPage.orderCountTips')+'：<span style="color:red;">'+ this.clientCreateOrderSkuNum +'</span></p>' +
									 '<p>'+this.$t('commonInfo.expectedDeliveryQuantity')+'：<span style="color:red;">' + this.dialogForm.clientAppointCount + '</span></p>' +
									 '<p>'+this.$t('ksaWhFBXOrderPage.confirmToCreateOrderTips')+'</p>',
									  this.$t('tipsInfo.tip'), {
										dangerouslyUseHTMLString: true,
										confirmButtonText: this.$t('commonInfo.dialogBtnConfirm'),
										cancelButtonText: this.$t('commonInfo.dialogBtnCancel'),
										type: "warning"
									}
								).then(() => {
									this.submitOrderInfo(url);
								})
								.catch(() => {});
						}
					}
				})
			},
			submitOrderInfo(url){
				this.submitLoading = true;
				this.$http.post(url, this.dialogForm).then(res => {
					if (res.code == 200) {
						this.$message.success(this.$t('tipsInfo.success'));
						this.submitLoading = false;
						this.cancel();
						this.getList();
					} else {
						this.submitLoading = false;
						this.$message.error(res.msg);
					}
				}).catch(error => {
					this.submitLoading = false;
					this.$message.error(error.msg);
				})
			},
			// 关闭窗口
			closeDialog() {
				this.cancel();
			},
			// 取消
			cancel() {
				this.popup = false;
				this.dialogForm = {
					order_category:'',// FBA FBN SB2B
					// customer_order_no: '',
					skuListLink:'',
					label_url:'',//送仓文件
					newLabelLinkList:[],//换标文件
					fba_no: '',
					delivery_date: '',
					receiver_address: '',
					receiver_detail_address: '',
					productList: [],
					clientAppointCount:'',//期望送仓数量，需要和客户添加的商品总数量做对比
					send_company:'',//店铺ID
					exp:'',
					deliveryType:'1',//派送方式 默认送货上门
					contacts:'',//联系人或提货人
					contactsTel:'',//联系人电话或联系人电话
				};
				this.clientCreateOrderSkuNum = 0;
				this.clientCreateOrderSwapNum = 0;
				this.newLabelFileLink = [];
				this.clearFileListFlag = true;//清空子组件预览文件列表
				this.hasSkuNewItem = false;
				this.inventoryData = [];
				this.orderProductList = [];
				this.searchProductCode = '';
				
				this.fbxLink = '';
				this.fbxFlienName = '';
				this.fbxPreName = [];
				this.labelLink = '';
				this.labelFlienName = '';
				this.labelPreName = [];
				
				this.timePopup = false;
				this.timeForm = {
					productList: [],
					customer_order_no:'',
					delivery_date:'',
					receiver_address: '',
					receiver_detail_address: '',
					exp:'',
					order_category:'',
					label_url:'',
				}
				let that = this;
				setTimeout(function(){
					that.clearFileListFlag = false;//再改变回原来状态
				},100)
				
			},
			//上传成功
			handlesuccess(files, fileList) {
				//console.log(files);
				this.uploadloading = false;
			  if (files.code == 200) {
			    let that = this;
			    
			    if(files.data.productErrorInfoList.length == 0){
			    	this.$message.warning(this.$t('ksaWhFBXOrderPage.checkProductErrorTips'));
			    }else{
						// 判断返回的信息是否正确，如果导入的商品信息没问题则显示在列表上，检测有问题后台返回有问题信息，前端显示
						if(!files.data.success){
							this.errorData = files.data.productErrorInfoList;
							this.dialogErrorVisible = true;
						}else{
							that.$message.success(this.$t('tipsInfo.uploadSuccess'));
							that.dialogForm.skuListLink = files.data.skuListLink;
							that.inventoryData = that.inventoryData.concat(files.data.productErrorInfoList);
							that.inventoryData = that.quchong(that.inventoryData);
							that.checkProduct();
						}
						
						
			    }
			  } else if (files.code == 500) {
			    this.$message.error(files.msg);
			  }
			  // console.log(files);
			
			  if (files.code == 401) {
			    this.$message.error(this.$t('tipsInfo.loginOutTips'));
			    window.sessionStorage.removeItem("token");
			    return this.$router.push({
			      path: "/login"
			    });
			  }
			  // console.log(files);
			},
			// 上传时触发
			beforeUpload(file) {
				let fileNameStr = file.name;
				if(fileNameStr.length > 30){
					return this.$message.warning(this.$t('tipsInfo.uploadFileNameLimitTips'));
				}
			  var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
			  const extension = testmsg === "xls";
			  const extension2 = testmsg === "xlsx";
			  if (!extension && !extension2) {
			    this.$message.warning(
			      this.$t('tipsInfo.uploadExcelTypeTips')
			    );
			  }
			  return extension || extension2;
			},
			// 上传失败
			handleError(err, file, fileList) {
				this.uploadloading = false;
			},
			// 上传时
			handleProgress(event, file, fileList) {
				this.uploadloading = true;
			},
			// 查看图片
			handleViewImg(img){
				this.imgList = [];
				if(img.indexOf(',') == -1){
				this.imgList.push(img[0])
				}else{
				this.imgList = img.split(',');
				}
				this.showImg = true;
			},
			//退回总数量
		returnNum(row) {
			let num = 0;
			row.productList.forEach(item => {
				if(item.boxLabelNew == null){
					return 0;
				}else{
					num +=  parseInt(item.boxLabelNew);
				}
			})
			return num;
		},
			
		},
		created() {
			let token = window.sessionStorage.getItem("token");
			this.headers = {
				Authorization: token,
			}
			
			this.getList();
		},
		mounted() {}
	};
</script>
<style scoped>
	.listHeader {
		width: 100%;
		height: 50px;
		line-height: 50px;
		font-size: 22px;
	}

	.listHeader i {
		width: 4px;
		height: 40px;
		display: inline-block;
		background-color: #0077AA;
		float: left;
		margin: 5px 10px;
	}

	.listHeader span {
		display: inline-block;
		float: left;
	}
	.dialog_orderNo{
		width: 100%;
		height: 50px;
		line-height: 40px;
	}
	.dialog_orderNo label{
		margin-left: 40px;
		font-weight: bold;
		border-radius: 8px;
		font-size: 20px;
		padding: 5px 15px;
		border: 1px solid #EBEEF5;
		box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
	}

	.pageBottom {
		padding: 20px;
	}
</style>
<style>
.el-picker-panel__footer .el-picker-panel__link-btn.el-button--text {
  display: none;
}
.deliveryTip{
	width: 630px;
	margin-left: 150px;
	margin-bottom: 10px;
	background-color: #fffdd6;
	border-radius: 4px;
	padding: 5px 10px;
}
.deliveryTip h4{
	margin: 0;
	line-height: 30px;
}
.deliveryTip i{
	margin: 0 5px;
}
.deliveryTip span{
	display: block;
	height: 20px;
	line-height: 20px;
	text-indent: 20px;
}
.deliveryTip label{
	display: block;
	height: 20px;
	line-height: 20px;
	text-indent: 40px;
	font-weight: bold;
}
.fileUploadBtn{
	float: left;
	margin: 0 10px;
	max-width: 220px;
}

</style>
<style scoped lang="scss">
	// 阿拉伯语
	[lang="ar"] {
		.listHeader{
			i{
				float: right;
			}
			span{
				float: right;
			}
		}
		
		.el-form-item__content{
			.el-button--primary{
				margin-left: 10px;
			}
		}
		// 表格
		::v-deep .el-table__fixed-right{
			left: 0;
			right: auto;
			.el-table__fixed-body-wrapper{
				left: 0;
				right: auto;
			}
		}
		::v-deep .el-table__fixed-right .el-table__fixed-header-wrapper{
			left: 0;
			right: auto;
		}
		::v-deep .fixed-width .el-button{
			margin: 0 5px 0 0 !important;
			i{
				margin-left: 5px;
			}
		}
		::v-deep .el-dropdown{
			margin: 0 5px 0 0 !important;
			.batchInfoItem{
				width: 20px;
				height: 20px;
				left: -15px;
				right: auto;
				top:-5px;
			}
			span{
				margin-left: -5px;
				float: right;
			}
			i{
				float: right;
				margin: 6px 0 0 5px;
			}
		}
		::v-deep .is-dot {
			left: -2px;
			right: auto;
			top: 6px;
		}
		::v-deep .el-dialog__headerbtn{
			right: auto;
			left: 20px;
		}
		::v-deep .el-select .el-tag{
			margin: 0 5px;
		}
		::v-deep .el-select .el-tag__close.el-icon-close{
			right: 5px;
		}
		::v-deep .el-timeline-item__node--normal{
			right: -15px;
		}
		::v-deep .el-dialog__footer {
			text-align: left;
			.el-button {
				margin: 0 10px;
			}
		}
		.fileUploadBtn{
			float: right;
			margin: 0 10px;
			max-width: 220px;
		}
	}
</style>